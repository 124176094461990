import { DialogUtility } from '@syncfusion/ej2-popups';

var dialogBox;

export const errorBox = (message, okClickEvent) => {
    return messageBox('Error', message, 'error', okClickEvent);
}

export const warningBox = (message, okClicked) => {
    return messageBox('Warning', message, 'warning', okClicked);
}

export const confirmBox = (message, okClicked, cancelClicked) => {
    return messageBox('Confirmation', message, 'confirm', okClicked, cancelClicked);
}

export const successBox = (message, okClickEvent) => {
    return messageBox('Success', message, 'success', okClickEvent);
}

const messageBox = (title, message, boxType, okClickEvent, cancelClicked) => {
    switch (boxType) {
        case 'success':
            DialogUtility.alert({
                closeOnEscape: true,
                content: message,
                title: title,
                showCloseIcon: true,
                position: { X: 'center', Y: 200 },
                //cssClass: 'dialog-message-iwow',
                close: okClickEvent
            });

            break;
        case 'confirm':
            dialogBox = DialogUtility.confirm({
                closeOnEscape: false,
                content: message,
                title: title,
                showCloseIcon: false,
                position: { X: 'center', Y: 200 },
                cancelButton: {
                    text: 'No',
                    click: () => {
                        cancelClicked();
                        dialogBox.hide();
                    }
                },
                okButton: {
                    text: 'Yes',
                    click: () => {
                        okClickEvent();
                        dialogBox.hide();
                    }
                }
            });
            break;
        default:
            DialogUtility.alert({
                closeOnEscape: true,
                content: message,
                title: title,
                showCloseIcon: true,
                position: { X: 'center', Y: 200 },
                //cssClass: 'dialog-message-iwow',
                close: okClickEvent
            });
            break;
    }

    var dialogs = document.getElementsByClassName("e-dlg-container");

    if (dialogs) {
        for (var dialog of dialogs) {
            dialog.style.backgroundColor = 'transparent';

            switch (boxType) {
                case 'success':
                case 'error':
                case 'warning':
                    var contents = dialog.getElementsByClassName('e-dlg-content');

                    if (contents) {
                        for (var content of contents) {
                            if (content.classList.contains('e-cust-success-dlg')) {
                                continue;
                            }

                            content.classList.add('e-cust-success-dlg');
                        }
                    }
                    break;
                default:
                    break;
            }
        }
    }
}