import { act } from 'react';
import { ActionTypeList } from '../../constants'

const initialState = {
    loading: false,
    isSuccess: false,
    successMessage: '',
    toShowMessage: false,
    error: "",
};

const userInsertStatusPayload = (state = initialState, action) => {

    switch (action.type) {
        case ActionTypeList.USER_SETTING_INSERTED:
            let isSuccess = true;
            let message = action.payload

            if (action.payload.error) {
                isSuccess = false;
                message = action.payload.message;
            }

            return {
                loading: false,
                isSuccess: isSuccess,
                successMessage: message,
                toShowMessage: true,
                error: ""
            };
        case ActionTypeList.USER_SETTING_INSERT:
            return {
                loading: true,
                isSuccess: false,
                toShowMessage: true,
                error: action.payload
            };
        case ActionTypeList.USER_SETTING_INSERTED_HIDE_MSG:
            return {
                loading: false,
                toShowMessage: false,
                isSuccess: false,
                error: "",
                successMessage: ``
            };
        default:
            return state;
    }
}

export default userInsertStatusPayload;