import { ActionTypeList } from '../../constants'

const initialTargetSettingState = {
    loading: false,
    error: "",
    targetSetting: {
        name: '',
        targetId: '',
        description: '',
        url: '',
        loginFormElements: [],
        customHeaders: [],
        customCookies: []
    },
};

export const currentTargetSettingPayload = (state = initialTargetSettingState, action) => {

    console.log(`${new Date()}: Reducer currentTargetSettingPayload ${action.type} data: ${JSON.stringify(action.payload)} `);

    switch (action.type) {
        case ActionTypeList.TARGET_SETTING_DETAIL_FETCHED:

            return {
                loading: false,
                targetSetting: action.payload,
                error: ""
            };
        case ActionTypeList.TARGET_SETTING_FETCH_DETAIL:

            return {
                loading: true,
                targetSetting: {},
                error: action.payload
            };
        case ActionTypeList.TARGET_SETTING_NEW_FLAG_TOGGLED:

            return {
                loading: false,
                targetSetting: {},
                error: action.payload
            };
        case ActionTypeList.TARGET_SETTING_FIELD_UPDATED:

            const payload = action.payload;

            return {
                loading: false,
                targetSetting: { ...state.targetSetting, [payload.fieldName]: payload.fieldValue },
                error: ""
            };
        case ActionTypeList.TARGET_SETTING_UPDATED:
            return {
                loading: false,
                targetSetting: { ...state.targetSetting, loginPassword: "********************" },
                error: ""
            };
        default:

            return state;
    }
}

const initialAccountTargetSettingState = {
    loading: false,
    error: "",
    targetSetting: {
        name: '',
        targetId: '',
        description: '',
        url: ''
    },
};

export const currentAccountTargetSettingPayload = (state = initialAccountTargetSettingState, action) => {

    switch (action.type) {
        case ActionTypeList.ACCOUNT_TARGET_SETTING_CURRENT_DETAIL_UPDATED:
            return {
                loading: false,
                targetSetting: action.payload,
                error: ""
            };
        case ActionTypeList.ACCOUNT_TARGET_SETTING_UPDATE_CURRENT_DETAIL:
            return {
                loading: true,
                targetSetting: {},
                error: action.payload
            };
        case ActionTypeList.TARGET_SETTING_NEW_FLAG_TOGGLED:
            return {
                loading: false,
                targetSetting: {},
                error: action.payload
            };
        default:
            return state;
    }
}