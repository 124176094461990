import { ActionTypeList } from '../constants/index'

const initialState = {
    loading: false,
    currentLoginUser: undefined,
    isCurrentLoginUserUpdated: false,
    error: "",
    message: ``,
    isCurrentLoginUserEditMode: false,
    isCurrentLoginUserModified: false,
    subscriptionPlanChooserPageVisitCount: 0
};

const currentLoginUserPayload = (state = initialState, action) => {

    switch (action.type) {
        case ActionTypeList.SECURITY_LOGIN_INFO_GOTTEN:
        case ActionTypeList.AUTHENTICATION_UPDATE_CURRENT_LOGIN_USER:

            return {
                ...state,
                currentLoginUser: action.payload,
                error: ""
            };
        case ActionTypeList.LOGIN_USER_FIELD_UPDATED:

            const payload = action.payload;
            return {
                ...state,
                currentLoginUser: { ...state.currentLoginUser, [payload.fieldName]: payload.fieldValue },
                isCurrentLoginUserModified: true,
                error: ""
            };
        case ActionTypeList.LOGIN_USER_UPDATED:
            return { ...state,
                isCurrentLoginUserUpdated: true,
                message : action.payload,
                isCurrentLoginUserEditMode: false,
                isCurrentLoginUserModified: false,
            };
        case ActionTypeList.LOGIN_USER_UPDATE:
            return {
                ...state,
                isCurrentLoginUserUpdated: false,
                error: action.payload,
                message: ``
            };
        case ActionTypeList.LOGIN_USER_EDIT_MODE_UPDATED:
            return {
                ...state,
                isCurrentLoginUserEditMode: action.payload,
                isCurrentLoginUserUpdated: false,
                message: ''
            };
        case ActionTypeList.CURRENT_LOGIN_INCREMENT_SUBSCRIPTION_PLAN_CHOOSER_PAGE_VISI_COUNT:
            return {
                ...state,
                subscriptionPlanChooserPageVisitCount: state.subscriptionPlanChooserPageVisitCount + 1,
            };
        case ActionTypeList.TOS_ACCEPTANCE_SUBMITTED:
            return {
                ...state,
                currentLoginUser: {
                    ...state.currentLoginUser,
                    permissions: action.payload.userInfo?.permissions
                }
            };
        default:
            return state;
    }
}

export default currentLoginUserPayload;